import { computed, ref, Ref } from 'vue';
import { FormContext, useField } from 'vee-validate';

interface FormFieldProps {
    name: string | Ref<String | string>;
    label?: string | Ref<String | string | undefined>;
    required?: boolean | Ref<Boolean | boolean | undefined>;
    form: FormContext<any>;
}

export const useFormField = <Value = any>(props: FormFieldProps) => {
    const name = typeof props.name === 'object' ? props.name : ref(props.name);
    const label = typeof props.label === 'object' ? props.label : ref(props.label);
    const required = typeof props.required === 'object' ? props.required : ref(props.required);

    const id = computed(() => name.value.replaceAll('[', '-').replaceAll(']', ''));
    const display = computed(() => [label?.value ?? '', required?.value ? '*' : ''].join(' ').trim());
    const path = computed(() => name.value.replaceAll('[', '.').replaceAll(']', ''));
    const error = computed(() => field.meta.touched && !field.meta.valid ? field.errorMessage : null);

    const form = props.form;
    const field = useField<Value>(path.value, { form: form });

    const classNames = computed(() => ({
        'is-invalid': !!error.value,
        'is-valid': !error.value && field.value.value,
        'is-touched': field.meta.touched,
        'is-dirty': field.meta.dirty,
    }));

    return {
        id,
        display,
        path,
        field,
        value: field.value as Ref<Value>,
        error,
        setValue: (value) => {
            field.setValue(value);
            field.setTouched(true);
        },
        classNames,
    };
};
