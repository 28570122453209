<template>
    <div>
        <slot name="toggler" v-bind="{ toggle, hide, show }">
            <div @click="toggle">Toggle Me</div>
        </slot>

        <div class="popup__wrapper" v-if="visible" @keyup.esc="hide">
            <div class="popup__inner">
                <div class="popup__x popup--closer" @click="hide">
                    <IconX />
                </div>

                <div class="popup__content">
                    <div class="page__content__inner">
                        <slot name="content" v-bind="{ toggle, hide, show }"> Content </slot>
                    </div>
                </div>
            </div>
            <div class="popup__green popup--closer" @click="hide"></div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import IconX from '@/svg/icon-x.svg?component';

const visible = ref(false);
const toggle = () => (visible.value = !visible.value);
const hide = () => (visible.value = false);
const show = () => (visible.value = true);
</script>
