import { defineStore } from 'pinia';
import { ref } from 'vue';
import { User } from '@/vue/types';

export const useUserStore = defineStore('userStore', () => {
    const userSessionData = window.userSession as any;
    const loggedIn = ref<boolean>(userSessionData?.logged_in || false);
    const logoutUrl = ref(userSessionData.logout_url);
    const resetUrl = ref(userSessionData.reset_url);
    const user = ref<User | null>(userSessionData?.user || null);

    const setUser = (data?: User | null) => {
        user.value = data || null;

        if (data) {
            loggedIn.value = true;
        }
    };

    return {
        loggedIn,
        logoutUrl,
        resetUrl,
        user,
        setUser
    };
});
