<template>
    <div class="form-group">
        <div class="form-floating" :class="classNames">
            <input
                ref="el"
                v-bind="$attrs"
                :id="`input-${id}`"
                :type="type"
                class="form-control"
                :class="classNames"
                :name="name"
                v-model="field.value.value"
                :placeholder="display"
                @blur="field.setTouched(true)"
            />
            <label :for="`input-${id}`">{{ display }}</label>
            <div v-if="type === 'date'" class="input__icon">
                <IconDate />
            </div>

            <div v-if="help" class="form-field__info">
                <IconInfo @click="showHelp = !showHelp" />
                <div class="tooltipp" v-if="showHelp">{{ help }}</div>
            </div>
        </div>

        <div class="invalid-feedback" v-show="error">{{ error }}</div>
        <div class="input-description" v-show="!error && description">{{ description }}</div>
    </div>
</template>

<script lang="ts" setup>
import { inject, ref, toRefs } from 'vue';
import { useFormField } from '@/vue/components/form/helper';
import { FormContext } from 'vee-validate';
import { FormContextKey } from '@/vue/keys';
import IconInfo from '@/svg/icon-info.svg?component';
import IconDate from '@/svg/icon-date.svg?component';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: false,
        default: () => 'text',
    },
    label: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: () => false,
    },
    help: {
        type: String,
        required: false,
    },
});

const form: FormContext = inject(FormContextKey)!;
const { name = '', label, required } = toRefs(props);

const { id, field, display, path, value, error, classNames } = useFormField({
    name: name,
    label: label,
    required: required,
    form: form,
});

const showHelp = ref(false);
</script>
