import axios from 'axios';
import {createI18n} from "vue-i18n";

let messages = {};

const fetch = async () => {
    const res = await axios.get('/api/lang.json');

    messages = res.data;
};

export const makeI18nConfig = async () => {
    if (Object.keys(messages).length === 0) {
        await fetch();
    }

    return {
        messages,
        fallbackLocale: 'en',
        locale: 'de',
        injectGlobal: true,
        legacy: false
    };
};

export const makeI18n = async () => {
    const i18nConfig = await makeI18nConfig();
    return createI18n(i18nConfig)
};
