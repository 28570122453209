import { startAlpine } from '@/alpine/boot';
import { registerComponent } from '@/alpine/helper';

import { FixedHeaderComponent } from '@/alpine/components/header';
import { FormComponent } from '@/alpine/components/form';
import { SwiperComponent } from '@/alpine/components/swiper';

registerComponent('FixedHeader', FixedHeaderComponent);
registerComponent('Form', FormComponent);
registerComponent('Swiper', SwiperComponent);

// Start the Alpine Engine
startAlpine();
