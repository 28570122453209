import './validation';
import mitt from 'mitt';
import { Settings } from 'luxon';
import { registerComponents } from './components';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { makeI18n } from '@/vue/i18n';
import ClickOutside from './directives/clickOutsideDirective';

const pinia = createPinia();

export default async (el: Element) => {
    const i18n = await makeI18n();

    const app = createApp({
        mounted() {
            Settings.defaultLocale = this.i18n?.locale?.toLowerCase().replace('_', '-') || 'de-ch';
        },
        methods: {
            ellipsis(value: string, length: number) {
                return value.length > length ? `${value.substring(0, length)}...` : value;
            },
        },
    });

    registerComponents(app);

    app.config.globalProperties.$bus = app.config.globalProperties.$bus || mitt();
    app.use(i18n);
    app.use(pinia);
    app.mount(el);
    app.directive('click-outside', ClickOutside);

    return app;
};
