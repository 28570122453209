import CreateVue from './vue';
import {makeI18n} from "@/vue/i18n";

await makeI18n();

const nodes = document.querySelectorAll('[data-vue-instance]');

if (nodes.length > 0) {
    for (const node of nodes) {
        CreateVue(node);
    }
}
