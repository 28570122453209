<template>
    <div class="login__wrapper">
        <component
            v-if="!userStore.loggedIn"
            :is="modes[currentMode]"
            v-model="currentMode"
            @login-success="emit('login-success')"
            :hide-register="hideRegister"
            :login-title="loginTitle"
            :login-text="loginText"
        ></component>
        <a v-else :href="userStore.logoutUrl" class="btn btn--header-secondary btn--icon">
            {{ $t('labels.logout') }}
        </a>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import UserLogin from '@/vue/components/auth/UserLogin.vue';
import UserResetPassword from '@/vue/components/auth/UserResetPassword.vue';
import UserRegister from '@/vue/components/auth/UserRegister.vue';
import { useUserStore } from '@/vue/store/user';

defineProps({
    hideRegister: {
        type: Boolean,
        required: false,
    },
    loginTitle: {
        type: String,
        required: false,
    },
    loginText: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['login-success']);
const userStore = useUserStore();

const modes = ref({
    login: UserLogin,
    resetPassword: UserResetPassword,
    register: UserRegister,
});

const currentMode = ref<string>('login');
</script>
