<template>
    <div class="form-group group--checkbox">
        <div class="custom-control custom-checkbox" :class="classNames">
            <label class="custom-control-label" :for="`input-${id}`" @blur="field.setTouched(true)">
                {{ display }}
                <input
                    :id="`input-${id}`"
                    class="custom-control-input"
                    :class="classNames"
                    type="checkbox"
                    :name="name"
                    @input="(event) => updateValue(event.target.checked)"
                    @blur="field.setTouched(true)"
                    :checked="isChecked"
                />
                <span class="checkmark"></span>
            </label>
        </div>

        <div class="invalid-feedback" v-show="error">{{ error }}</div>
        <div class="input-description" v-show="!error && description">{{ description }}</div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, PropType, toRefs } from 'vue';
import { useFormField } from '@/vue/components/form/helper';
import { FormContextKey } from '@/vue/keys';
import { FormContext } from 'vee-validate';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    checkedValue: {
        type: [Boolean, String, Number],
        required: false,
        default: () => true,
    },
    uncheckedValue: {
        type: [Boolean, String, Number],
        required: false,
        default: () => false,
    },
    label: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});

const form: FormContext = inject(FormContextKey)!;
const { name = '', label, required } = toRefs(props);

const { id, field, display, path, value, error, classNames } = useFormField({
    name: name,
    label: label,
    required: required,
    form: form,
});

const updateValue = (checked) => {
    if (checked) {
        value.value = props.checkedValue;
    } else {
        value.value = props.uncheckedValue;
    }

    field.setTouched(true);
};

const isChecked = computed(() => {
    return !!value.value;
});
</script>

<style scoped>
.invalid-feedback {
    display: block !important;
}
</style>
