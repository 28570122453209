<template>
    <div class="header__user__menu">
      <Stack v-if="!userStore.loggedIn">
        <template #toggler="{ show }">
          <li class="nav-item">
            <div class="header__user">
              <div class="header__user__item" @click="show">
                <span>{{ $t('labels.login') }}</span>
                <IconLogin />
              </div>
            </div>
          </li>
        </template>
  
        <template #content>
          <UserAuth />
        </template>
      </Stack>
  
      <li v-else class="nav-item dropdown__wrapper">
        <div class="header__user" @click="showUserMenu = !showUserMenu" :class="{ 'active': showUserMenu }">
          <div class="header__user__item">
            <span>{{ userStore.user.firstname }} {{ userStore.user.lastname }}</span>
            <IconLogin />
          </div>
        </div>
  
        <ul v-if="showUserMenu" class="dropdown__menu">
          <li>
            <a :href="userStore.logoutUrl" class="dropdown__item">
              {{ $t('labels.logout') }}
            </a>
          </li>
          <li>
            <a :href="myAccountUrl" class="dropdown__item">
              {{ $t('labels.manage_account') }}
            </a>
          </li>
        </ul>
      </li>
    </div>
  </template>
  
  <script setup lang="ts">
  import { useUserStore } from '@/vue/store/user';
  import Stack from '@/vue/components/Stack.vue';
  import UserAuth from '@/vue/components/auth/UserAuth.vue';
  import IconLogin from '@/svg/icon-login.svg?component';
  import { ref } from 'vue';
  
  const userStore = useUserStore();
  const showUserMenu = ref(false);
  
  defineProps({
    myAccountUrl: {
      type: String,
      required: true,
    },
  });
  
  const show = () => {
    showUserMenu.value = !showUserMenu.value;
  };
  </script>
  