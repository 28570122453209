import { AlpineComponent } from '@/alpine/helper';
import Swiper, { Navigation, Pagination } from 'swiper';

export class SwiperComponent extends AlpineComponent {
    public swiper?: Swiper;
    public config = {};

    constructor(config) {
        super();
        this.config = config;
    }

    init() {
        let init = false;

        const options: any = Object.assign(
            {},
            {
                modules: [Navigation, Pagination],
                // Optional parameters
                direction: 'horizontal',
                speed: 1400,
                autoplay: {
                    delay: 7000,
                },
                slidesPerView: 1,
                spaceBetween: 15,
                breakpoints: {
                    1201: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                },
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            this.config
        );

        this.swiper = new Swiper(this.$refs.container as HTMLElement, options);

        this.$refs.container.addEventListener('lazyloaded', () => {
            if (!init) {
                this.swiper?.init();
                init = true;
            }
        });
    }
}
