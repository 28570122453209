import $ from 'jquery';

$(() => {
 

    // Open Main Navigaton
    $('.main__navigation li.nav-menu > a').click(function(e) {
        e.preventDefault();
        if($(this).closest('.hovered').hasClass('hovered')) {
            $(this).closest('.hovered').removeClass('hovered');
            $('.overlay__closer').fadeOut();
        } else {
            $(this).parent().addClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
            $('.overlay__closer').fadeIn();
        }
    });
    $('.drop-menu__ul > .submenu__inner > li.parent > a').click(function(e) {
        e.preventDefault();
        $(this).parent().addClass('hovered');
        $(this).parent().siblings().removeClass('hovered');
    });
    $('.menu--closer, .overlay__closer').click(function() {
        $('.main__navigation li.nav-menu ').removeClass('hovered');
        $('.overlay__closer').fadeOut();
    });

    // Menü Hight Calc
    $('.main__navigation li.nav-menu').click(function () {
        var t = 0; // the height of the highest element (after the function runs)
        var childElem = $(this).find('.submenu__wrapper');
        $(childElem).each(function () {
            console.log($(this));
            var elem = $(this);
            elem.height('auto');
            if (elem.height() > t) {
                t = elem.height();
            }
        });
        $(childElem).height(t);
    });



    // Dropdown
    $('.dropdown__switcher').click(function() {
        $(this).siblings('.dropdown__menu').toggle();
        $(this).closest('.dropdown__wrapper').toggleClass('active');
    });

    // Select opener
    $('.form-select').click(function() {
        $(this).toggleClass('active');
        $(this).find('ul').fadeToggle();
    });

    // Select Value transfer
    $('.form-select ul li').click(function() {
        $(this).closest('ul').siblings(".select-input").val($(this).text().trim());
        $(this).closest('ul').siblings(".select__current").text($(this).text().trim());
    });
    

    // Header Modul Height Controller
    function sliderHeight() {
        $('.page__header__image').css('height', 'auto');
        var contentHeight = $('.page__header__content').outerHeight();
        var imageHeight = $('.page__header__image').outerHeight();

        if(contentHeight > imageHeight) {
            $('.page__header__image').css('height', contentHeight);
        } else {
            $('.page__header__image').css('height', 'auto');
        }
    }
    sliderHeight();
    $(window).resize(function(){
        sliderHeight();
    } );

    // Header Controller
    $('.page-section.bg--light').prev('.page__header').addClass('light-section-next');


    // Revealer
  
   // Scroll Animation Content
   $(function(){
        var $elems = $('.animateblock');
        var winheight = $(window).height();
        var fullheight = $(document).height();

        animate_elems();
        $(window).scroll(function(){
            animate_elems();
        });

        function animate_elems() {
            var wintop = $(window).scrollTop(); // calculate distance from top of window

            // loop through each item to check when it animates
            $elems.each(function(){
                var elm = $(this);

                if(elm.hasClass('animated')) { return true; } // if already animated skip to the next item

                var topcoords = elm.offset().top; // element's distance from top of page in pixels

                if(wintop > (topcoords - (winheight*.90))) {
                    // animate when top of the window is 3/4 above the element
                    elm.addClass('animated');
                }
            });
        }
    }); 

    // Checks if any particular element is in viewport
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementTop < viewportBottom;
    };
    
    // Animate numbers when scrolled into view
    $(window).scroll(function() {
        $('.numbers__content .numbers__number').each((i, el) => {
        var $counter = $(el);
        if (!$counter.isInViewport() || $counter.data('animation-started'))
            return;
    
        $counter.data('animation-started', true).prop('Counter', 0).animate({
            Counter: $counter.text()
        }, {
            duration: 3000,
            easing: 'swing',
            step: function(now) {
            $counter.text(Math.ceil(now));
            }
        });
        });
    });



    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
    
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
    
        return ((elemTop <= docViewTop));
    }

    // Make fixed Navigation
    var lastScrollTop = 0;
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 300) {
            $('body').addClass('fixed-header');
        } else {
            $('body').removeClass('fixed-header');
        }

        // Add Class when scrolling one window height
        var st = $(this).scrollTop();
        if (st > lastScrollTop){
            if ($(window).scrollTop() > window.innerHeight) {
                $('body').addClass('viewport--left');
            } else {
                $('body').removeClass('viewport--left');
            }
        } else {
            $('body').removeClass('viewport--left');
        }
        lastScrollTop = st;


        // Is Element in viewport
        $('.course__list__table__header').each(function () {
            if (isScrolledIntoView(this) === true) {
                $('body').addClass('course-header--active');
            }
            else {
                $('body').removeClass('course-header--active');
            }
        });
    });  
 

});

