<template>
    <div class="input__wrapper">
        <label v-if="props.label" class="text--legend">{{ props.label }}</label>
        <div class="input__icon form-group">
            <input v-model="searchValue" @keyup.enter="emit('onEnter')" @keyup="getAutocompleteData" type="text" :placeholder="props.placeholder" class="form-control" />
            <IconLoupe v-if="props.iconLoupe" @click="emit('clickOnIcon')"/>
        </div>

        <slot
            v-if="show"
            :autocompleteData="autocompleteData"
            :closeAutocomplete="closeAutocomplete"
            :selectAutocomplete="selectAutocomplete"
        ></slot>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import IconLoupe from '@/svg/icon-loup-middle.svg?component';
import { debounce } from 'lodash';

const emit = defineEmits(['change', 'onEnter', 'clickOnIcon']);

let controller = new AbortController();

const props = defineProps({
    placeholder: {
        type: String,
        required: true,
    },
    minLength: {
        type: Number,
        required: true,
    },
    onlyFireOnSelection: {
        type: Boolean,
        required: false,
    },
    iconLoupe: {
        type: Boolean,
        required: false,
    },
    label: {
        type: String,
        required: false,
    },
    apiUrl: {
        type: String,
        required: false,
    },
    siteHandle: {
        type: String,
        required: false,
    },
    loadAfterSelection: {
        type: Boolean,
        required: false,
    },
});

const show = ref(false);
const searchValue = ref('');
const autocompleteData = ref<Array<String>>([]);
const ajaxRunning = ref(false);

const doRequest = debounce(async (term: string) => {
    if (searchValue.value.length > props.minLength) {
        if (!props.onlyFireOnSelection) {
            emit('change', term);
        }

        if (props.apiUrl) {
            controller.abort();
            controller = new AbortController();

            ajaxRunning.value = true;
            const response = await axios.get<Any>(props.apiUrl, {
                signal: controller.signal,
                params: {
                    search: term,
                    siteHandle: props.siteHandle,
                },
            });
            autocompleteData.value = response.data;

            ajaxRunning.value = false;
            show.value = true;
        } else {
            show.value = false;
        }

        if (!props.onlyFireOnSelection) {
            show.value = term.length > props.minLength;
        }
    }
}, 50);

const getAutocompleteData = async () => {
    doRequest(searchValue.value);
};

const selectAutocomplete = (autocompleteValue: any) => {
    searchValue.value = autocompleteValue;
    autocompleteData.value = [];

    if (props.onlyFireOnSelection) {
        emit('change', searchValue.value);
    }

    if (props.loadAfterSelection) {
        getAutocompleteData();
    }
};

const closeAutocomplete = () => {
    autocompleteData.value = [];
    show.value = false;
};
</script>
